<template>
	<div class="login-container">
		<div class="login_img">
			<img src="~@/assets/bg_img.jpg" alt="" />
		</div>

		<div class="login">
			<div class="logon_tip">欢迎登陆</div>
			<div class="login_msg">
				<div class="login_text">帐号</div>
				<input type="text" id="loginName" placeholder="请输入账号" class="userName" v-model.trim="loginUsername" />
				<div class="login_text">密码</div>
				<input type="password" id="loginPassword" placeholder="请输入密码" class="password"
					v-model.trim="loginPassword" />
				<div class="login_text">验证码</div>
				<div class="verify-box">
					<input type="text" placeholder="请输入验证码" class="yanzheng" tk="verify_code"
						v-model.trim="verify_code" />
					<img class="yanzheng_code" tk="captcha" :src="codeUrl" @click="switchVerifyCode" />
					<a href="javascript:;" class="changeCode" @click="switchVerifyCode">换一张</a>
				</div>
			</div>
			<div class="login_bnt" tk="login" @click="login">登陆</div>
		</div>
	</div>
</template>

<script>
	const CryptoJS = require("crypto-js");
	export default {
		name: "LoginView",
		props: {},
		data() {
			return {
				loginUsername: "",
				loginPassword: "",
				verify_code: "",
				codeUrl: '',
			};
		},
		mounted() {
			this.switchVerifyCode() // 获取验证码
			try {
				var url = new URL(document.referrer);
				var refer_host = url.hostname;
			} catch(e){
				var refer_host = '';
			}
			this.$tools.tyym.pushEvent('门户-登陆', {
				"tkzj_modId": 'portal-login',
				"referer": refer_host
			});
			//pvuv
			this.$tools.tyym.pushEvent("tkzj_view_mod",{
				"tkzj_modId": 'portal-login',// 模块ID
				"referer": refer_host
			});
		},
		methods: {
			switchVerifyCode() {
				console.log('process.env :>> ', process.env);
				console.log('process.env.VUE_APP_BASE_API :>> ', process.env.VUE_APP_BASE_API);
				this.codeUrl = process.env.VUE_APP_BASE_API+`/image/captcha?rand=${Math.random()}`
			},
			login() {
				console.log("login");
				if (!this.loginUsername) {
					this.$message({
						message: "用户名不能为空",
						type: "warning",
					});
					return;
				}
				if (!this.loginPassword) {
					this.$message({
						message: "密码不能为空",
						type: "warning",
					});
					return;
				}
				if (!this.verify_code) {
					this.$message({
						message: "验证码不能为空",
						type: "warning",
					});
					return;
				}
				let prop = "portal.taikangjny.com";
				let host = window.location.host;
				if (host == prop) {
					var key = CryptoJS.enc.Utf8.parse('tkrsbeszhanghb22');
				} else {
					var key = CryptoJS.enc.Utf8.parse('taikaghealthcare');
				}
				let encryptedData = CryptoJS.AES.encrypt(this.loginPassword, key, {
					mode: CryptoJS.mode.ECB,
					padding: CryptoJS.pad.Pkcs7
				});
				let password = encryptedData.toString();
				console.log(password);

				this.$getInterfaceData(`/index/login`, 'POST', {
					username: this.loginUsername,
					password: password,
					client: 'portal',
					captcha: this.verify_code
				}).then(res => {
					console.log('res :>> ', res);
					if (res.data.code == '0') {
						this.$message({
							message: "登录成功",
							type: "success",
						});
						this.$store.commit('login', {
							subcode: 'portal',
							username: res.data.data.portal.name,
							loginname: res.data.data.portal.userName,
							token: res.data.data.portal.token
						});
						var user_info = {
							name: this.$store.state.SubSystem['portal'].username,
							loginName: this.$store.state.SubSystem['portal'].loginname,
							orgCd: '',
							orgName: '',
						}
						this.$tools.tyym.setUserInfo(user_info);
						this.$router.replace({ name: 'home' })
					} else {
						this.$message({
							message: res.data.msg,
							type: "error",
						});
					}
				}).catch(err => {
					console.log('err :>> ', err);
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.login-container {
		width: 100%;
		height: 100vh;
		margin: 0;
		padding: 0;
		// overflow: -Scroll;
		overflow-y: hidden;
		display: flex;

		.login_img {
			width: 75%;
			height: 100%;

			img {
				width: 100%;
				vertical-align: middle;
			}
		}

		.login {
			width: 25%;
			margin: 0 auto;
			background: #fff;

			.logon_tip {
				font-size: 36px;
				font-weight: normal;
				color: #000000;
				text-align: center;
				margin: 108px auto;
			}

			.login_msg {
				margin-left: 12%;
				text-align: left;
			}

			.login_text {
				font-size: 14px;
				font-weight: 600;
				margin-top: 18px;
			}

			.userName,
			.password {
				width: 86%;
				height: 34px;
				background: #ffffff;
				box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.08);
				border-radius: 16px;
				padding-left: 19px;
				padding-top: 0px;
				padding-bottom: 0px;
				border: none;
				font-size: 12px;
				color: #333;
				outline: none;
				display: block;
				margin: 10px -15px;
			}

			.verify-box {
				display: flex;
				align-items: center;

				.yanzheng {
					width: 90px;
					height: 34px;
					background: #ffffff;
					box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.08);
					border-radius: 16px;
					padding-left: 19px;
					padding-top: 0px;
					padding-bottom: 0px;
					border: none;
					font-size: 12px;
					color: #333;
					outline: none;
					float: left;
					margin-right: 10px;
					margin: 10px -15px;
				}

				.yanzheng_code {
					width: 80px;
					height: 38px;
					margin-top: 8px;
					padding-left: 12%;
				}

				.changeCode {
					font-size: 13px;
					color: #5790ee;
					padding-left: 10px;
					align-self: flex-end;
					transform: translateY(-4px);
				}

				.yanzheng_code {
					width: 80px;
					height: 40px;
					margin-top: 5px;
					cursor: pointer;
				}
			}

			.login_bnt {
				margin: 20% auto;
				text-align: center;
				background: linear-gradient(82deg, #fb8a68, #fb8a68, #fdd448);
				border-radius: 20px;
				height: 38px;
				line-height: 38px;
				color: #fff;
				// padding-top: 8px;
				width: 75%;
			}

			.other {
				text-align: center;
			}

			.other a {
				color: #c2bebe;
			}

			.other span {
				width: 2px;
				background: #fb8a68;
				height: 15px;
				display: inline-block;
				margin: -2px 15px;
			}
		}
	}
</style>
