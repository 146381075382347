import axios from "axios";
import Vue from "vue";

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // console.log('config',config)
    return config;
  },
  (err) => {
    // console.log('err',err)
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    // console.log('response',response)
    return response;
  },
  (error) => {
    // console.log('err',error,error.response.status)
    if (error.response) {
      switch (error.response.status) {
        // 401 清除token信息并跳转到登录页面(store.commit(types.LOGOUT);)
        case 401:
          // debugger;
          //跳转登录页面
          top.location.href = "/mcloudnavigation/login";
          break;
        case 403:
          // debugger;
          Vue.prototype.$message.warning({
            message: "用户无权限访问",
          });
          break;
      }
    }
    return Promise.reject(error);
  }
);

const Plugin = {
  install(vue) {
    let baseUrl = process.env.VUE_APP_BASE_API;
    vue.prototype.$getInterfaceData = function (url, type, data, headers, responseType) {
      var configHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      };

      configHeaders = Object.assign(
        {
          orgCd: sessionStorage.getItem("orgCd") ? sessionStorage.getItem("orgCd") : "",
        },
        configHeaders,
        headers
      );

      let Public = {
        //公共参数
        /*'srAppid': ""*/
      };
      /* let getTimestamp = new Date().getTime();
      if (url.indexOf("?") > -1) {
        url = url + "&tamp=" + getTimestamp;
      } else {
        url = url + "?timestamp=" + getTimestamp;
      } */
      let httpDefaultOpts = {
        //http默认配置
        method: type,
        url: baseUrl + url,
        headers: configHeaders,
        timeout: 30000, //超时时间
        params: Object.assign(Public, data),
        data: data,
        transformRequest: function (data) {
          // 这里可以在发送请求之前对请求数据做处理，比如form-data格式化等...
          if (responseType && responseType == "uploadFile") {
            return data;
          }
          data = JSON.stringify(data);
          return data;
        },
      };
      if (type == "GET") {
        delete httpDefaultOpts.data;
      } else {
        delete httpDefaultOpts.params;
      }
      //通过blob(用来存储二进制大文件)包装请求到的数据实现下载
      if (responseType && responseType == "downloadFile") {
        httpDefaultOpts.responseType = "blob";
      }
      let promise = new Promise(function (resolve, reject) {
        axios(httpDefaultOpts)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            // console.log('errorww', error)
            reject(error);
          });
      });
      return promise;
    };
  },
};

export default Plugin;
