var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c("header", { staticClass: "home-header" }, [
      _c("div", { staticClass: "top-box" }, [
        _vm._m(0),
        _c("div", { staticClass: "right" }, [
          _c("img", {
            staticClass: "avatar",
            attrs: { src: require("@/assets/images/avatar.png") },
          }),
          _c("div", { staticClass: "username" }, [
            _vm._v(_vm._s(_vm.username)),
          ]),
          _c("div", { staticClass: "logout", on: { click: _vm.logout } }, [
            _vm._v("退出登录"),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "home-body" }, [
      _c("div", { staticClass: "home-body-box" }, [
        _c("div", { staticClass: "left-box" }, [
          _c(
            "div",
            { staticClass: "service" },
            [
              _c("Title", { attrs: { title: "服务" } }),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _vm._l(_vm.fw, function (itm, idx) {
                    return [
                      _c("CardX", {
                        key: idx,
                        attrs: { cardInfo: itm, ckey: idx },
                        on: { CardClick: _vm.CardClick },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "customer" },
            [
              _c("Title", { attrs: { title: "客户" } }),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _vm._l(_vm.kh, function (itm, idx) {
                    return [
                      _c("CardX", {
                        key: idx,
                        attrs: { cardInfo: itm, ckey: idx },
                        on: { CardClick: _vm.CardClick },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "right-box" },
          [
            _c("Title", { attrs: { title: "管理" } }),
            _c(
              "div",
              { staticClass: "section" },
              [
                _vm._l(_vm.gl, function (itm, idx) {
                  return [
                    _c("CardY", {
                      key: idx,
                      attrs: { cardInfo: itm, ckey: idx },
                      on: { CardClick: _vm.CardClick },
                    }),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "floating-popup", on: { click: _vm.helpChatBot } },
      [_vm._v(" 系统使用助手 ")]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/images/logo2.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }