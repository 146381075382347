var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "login" }, [
      _c("div", { staticClass: "logon_tip" }, [_vm._v("欢迎登陆")]),
      _c("div", { staticClass: "login_msg" }, [
        _c("div", { staticClass: "login_text" }, [_vm._v("帐号")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.loginUsername,
              expression: "loginUsername",
              modifiers: { trim: true },
            },
          ],
          staticClass: "userName",
          attrs: { type: "text", id: "loginName", placeholder: "请输入账号" },
          domProps: { value: _vm.loginUsername },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.loginUsername = $event.target.value.trim()
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("div", { staticClass: "login_text" }, [_vm._v("密码")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.loginPassword,
              expression: "loginPassword",
              modifiers: { trim: true },
            },
          ],
          staticClass: "password",
          attrs: {
            type: "password",
            id: "loginPassword",
            placeholder: "请输入密码",
          },
          domProps: { value: _vm.loginPassword },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.loginPassword = $event.target.value.trim()
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _c("div", { staticClass: "login_text" }, [_vm._v("验证码")]),
        _c("div", { staticClass: "verify-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.verify_code,
                expression: "verify_code",
                modifiers: { trim: true },
              },
            ],
            staticClass: "yanzheng",
            attrs: {
              type: "text",
              placeholder: "请输入验证码",
              tk: "verify_code",
            },
            domProps: { value: _vm.verify_code },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.verify_code = $event.target.value.trim()
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _c("img", {
            staticClass: "yanzheng_code",
            attrs: { tk: "captcha", src: _vm.codeUrl },
            on: { click: _vm.switchVerifyCode },
          }),
          _c(
            "a",
            {
              staticClass: "changeCode",
              attrs: { href: "javascript:;" },
              on: { click: _vm.switchVerifyCode },
            },
            [_vm._v("换一张")]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "login_bnt",
          attrs: { tk: "login" },
          on: { click: _vm.login },
        },
        [_vm._v("登陆")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login_img" }, [
      _c("img", { attrs: { src: require("@/assets/bg_img.jpg"), alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }