var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CardY-container", on: { click: _vm.CardClick } },
    [
      _c("div", { staticClass: "top-box" }, [
        _c("img", {
          staticClass: "gallery",
          style: { filter: _vm.isDeveloping ? "blur(3px)" : "0px" },
          attrs: { src: _vm.CardData[_vm.ckey].imgurl, alt: "" },
        }),
        _vm.isDeveloping
          ? _c("img", {
              staticClass: "lock",
              attrs: { src: require("@/assets/images/lock.png"), alt: "" },
            })
          : _vm._e(),
      ]),
      _c("div", { staticClass: "middle-line" }),
      _c("div", { staticClass: "bottom-box" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.CardData[_vm.ckey].title)),
          ]),
          _vm.isDeveloping
            ? _c("div", { staticClass: "no-permission" }, [_vm._v("无权限")])
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "content",
            attrs: { title: _vm.CardData[_vm.ckey].content },
          },
          [_vm._v(" " + _vm._s(_vm.CardData[_vm.ckey].content) + " ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }