var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CardX-container", on: { click: _vm.CardClick } },
    [
      _c("div", { staticClass: "left-box" }, [
        _c("img", {
          staticClass: "gallery",
          style: { filter: _vm.isDeveloping ? "blur(2px)" : "0px" },
          attrs: { src: _vm.CardData[_vm.ckey].imgurl, alt: "" },
        }),
        _vm.isDeveloping
          ? _c("img", {
              staticClass: "lock",
              attrs: { src: require("@/assets/images/lock.png"), alt: "" },
            })
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "right-box" },
        [
          _vm.isDeveloping
            ? [_c("div", { staticClass: "developing" }, [_vm._v("开发中")])]
            : [
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.CardData[_vm.ckey].title)),
                  ]),
                  _vm._m(0),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    attrs: { title: _vm.CardData[_vm.ckey].content },
                  },
                  [_vm._v(" " + _vm._s(_vm.CardData[_vm.ckey].content) + " ")]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "arrow" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/arrow.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }