<template>
  <div class="CardX-container" @click="CardClick">
    <div class="left-box">
      <img class="gallery" :style="{ filter: isDeveloping ? 'blur(2px)' : '0px' }" :src="CardData[ckey].imgurl" alt="" />
      <img v-if="isDeveloping" class="lock" src="~@/assets/images/lock.png" alt="" />
    </div>
    <div class="right-box">
      <template v-if="isDeveloping">
        <div class="developing">开发中</div>
      </template>
      <template v-else>
        <div class="title">
          <div class="text">{{ CardData[ckey].title }}</div>
          <div class="arrow">
            <img src="~@/assets/images/arrow.png" alt="" />
          </div>
        </div>
        <div class="content" :title="CardData[ckey].content">
          {{ CardData[ckey].content }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardX",
  props: ["cardInfo", "ckey"],
  data() {
    return {
      isDeveloping: false, // 是否处于开发中
      CardData: {
        fwyw: {
          title: "福位系统",
          content: "墓位购买及退购、财务收/退款、打印合同、售后服务、佣金提成、墓位管理、商品管理及墓位销售相关报表等",
          imgurl: require("@/assets/images/福位系统2.png"),
        },
        anlh: {
          title: "安疗系统",
          content: "安宁疗护服务工单管理、患者信息，入住信息、服务信息、离院信息查看，病房占用率统计等。",
          imgurl: require("@/assets/images/安疗系统2.png"),
        },
        smly: {
          title: "哀伤疗愈系统",
          content: "哀伤疗愈服务工单、服务订单、商品下单、数据报表、订单商品明细等",
          imgurl: require("@/assets/images/生命礼仪系统1.png"),
        },
        sqqy: {
          title: "契约系统",
          content: "礼赞人生产品管理、订单管理、合同生成、申请退款、转让、变更使用人、变更承办人、投资指令操作、VIP服务管理及业绩报表等。",
          imgurl: require("@/assets/images/契约系统1.png"),
        },
        cpzx: {
          title: "乡村振兴系统",
          content: "维护文农产品基本信息、产品规格和套餐，管理文农产品分类、产品库存等",
          imgurl: require("@/assets/images/商品系统1.png"),
        },
        jypt: {
          title: "经营平台",
          content: "泰康生命关怀小程序运营平台，具备客户服务、活动推广，网络销售及互联网营销等功能。",
          imgurl: require("@/assets/images/经营平台1.png"),
        },
        yxzx: {
          title: "销管中心",
          content: "营销中心包含各业务类型的渠道佣金、销售提成数据计算、查看、审核；团队管理等相关模块。",
          imgurl: require("@/assets/images/销管中心1.png"),
        },
        xszc: {
          title: "CRM系统",
          content: "销售线索（墓地、契约）报备、线索跟踪、客户信息查看维护、客户订单查看、使用人信息维护；渠道管理；销售活动管理；售后投诉、客户及线索统计报表等",
          imgurl: require("@/assets/images/CRM系统1.png"),
        },
      },
    };
  },
  methods: {
    CardClick() {
      this.$emit("CardClick", this.ckey);
    },
  },
};
</script>

<style lang="less" scoped>
.CardX-container {
  display: flex;
  @media screen and (max-width: 1919px) {
    width: 329px;
    height: 113px;
  }
  @media screen and (min-width: 1920px) {
    width: 405px;
    height: 147px;
  }
  padding: 15px 16px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 1px rgba(52, 80, 55, 0.08);
  border-radius: 3.17px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 6px 3px rgba(52, 80, 55, 0.1);
  }

  .left-box {
    position: relative;
    width: 110px;
    height: 100%;
    background: white;
    margin-right: 16px;
    @media screen and (max-width: 1919px) {
      width: 110px;
    }
    @media screen and (min-width: 1920px) {
      width: 134px;
    }

    .gallery {
      width: 100%;
      height: 100%;
    }

    .lock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 21px;
      height: 28px;
    }
  }

  .right-box {
    flex: 1;
    height: 100%;
    // background: lightcoral;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .developing {
      font-family: PingFangSC-Medium;
      color: #1d3123;
      font-weight: 500;
      @media screen and (max-width: 1919px) {
        width: 48px;
        height: 22px;
        font-size: 16px;
      }
      @media screen and (min-width: 1920px) {
        width: 60px;
        height: 25px;
        font-size: 18px;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 1919px) {
        height: 22px;
      }
      @media screen and (min-width: 1920px) {
        height: 25px;
      }
      // margin-top: 3px;
      // margin-bottom: 9px;

      .text {
        font-family: PingFangSC-Medium;
        color: #1d3123;
        font-weight: 500;
        @media screen and (max-width: 1919px) {
          font-size: 16px;
        }
        @media screen and (min-width: 1920px) {
          font-size: 18px;
        }
      }

      .arrow {
        @media screen and (max-width: 1919px) {
          width: 14px;
          height: 14px;
        }
        @media screen and (min-width: 1920px) {
          width: 22px;
          height: 22px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .content {
      width: 100%;
      font-family: PingFangSC-Regular;
      color: #989aa6;
      font-weight: 400;
      // 超过两行显示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 1919px) {
        height: 34px;
        line-height: 17px;
        font-size: 12px;
      }
      @media screen and (min-width: 1920px) {
        height: 40px;
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
