<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="less">
#app {
  width: 100%;
  // min-width: 1260px;
  min-width: 1366px;
  height: 100vh;
  overflow: scroll;
  background-color: #f8fbff;
}
</style>

<script>
export default {
  name: "app",
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))));
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    if (!this.$store.state.SubSystem?.portal?.token && location.pathname != "/login") {
      // location.href = '/login';
      // TODO:跳转登录页
      this.$router.replace({ name: "login" });
    }
	this.$tools.tyym.init();
  },
  mounted() {
  	this.tyym_set_userinfo();
  },
  methods: {
	tyym_set_userinfo: function() {
		if(this.$store.state.SubSystem['portal'] && this.$store.state.SubSystem['portal'].loginname && this.$store.state.SubSystem['portal'].username) {
			var user_info = {
				name: this.$store.state.SubSystem['portal'].username,
				loginName: this.$store.state.SubSystem['portal'].loginname,
				orgCd: '',
				orgName: '',
			}
			this.$tools.tyym.setUserInfo(user_info);
		}
		
	},
 }
};
</script>
