import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		SubSystem: {}
	},
	mutations: {
		login(state, payload) {
			if(!state.SubSystem[payload.subcode]) {
				state.SubSystem[payload.subcode] = {};
			}
			state.SubSystem[payload.subcode].username = payload.username;
			state.SubSystem[payload.subcode].loginname = payload.loginname;
			state.SubSystem[payload.subcode].token = payload.token;
			state.SubSystem[payload.subcode].usercd = payload.usercd;
			state.SubSystem[payload.subcode].permission = payload.permission;
		},
		logout(state, subcode) {
			//state.SubSystem[subcode].token = '';
			state.SubSystem = {};
			sessionStorage.clear();
		}
	},
	actions: {},
	modules: {}
})
