module.exports = (vm) => {
	let env = process.env.VUE_APP_BASE_ENV;
	if(env == 'prod') {
		var config = {
			xszc: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'https://ttcrm.tkzj.taikang.com/pc/panel/jumpPanel.html?tk-jny-token=%token%',
			  name: '销售支持'
			},
			smly: {
			  code: 'RK6BW46J',
			  appId: 'tkzj-smly',
			  redirect: 'http://lesoms.taikang.com/shtadmin/modeSelect?tk-jny-token=%token%',
			  name: '哀伤疗愈'
			},
			sqqy: {
			  code: 'SC426_01',
			  appId: 'jny-lzrs',
			  redirect: 'https://iujy.iu95522.com/jiayuan_admin/index.php?m=admin&c=public&a=login&tk-jny-token=%token%',
			  name: '契约系统'
			},
			anlh: {
			  code: 'SC410-AL',
			  appId: 'jny-market',
			  redirect: 'https://anliao.iu95522.com/tologin?tk-jny-token=%token%',
			  name: '安疗系统'
			},
			fwyw: {
			  code: 'RK6BW46J',
			  appId: 'tkzj-tlning-sfi',
			  redirect: 'https://fi.taikang.com/auth/tk?tk-jny-token=%token%',
			  name: '福位系统'
			},
			yxzx: {
			  code: 'C7U4UXL8',
			  appId: 'tkzj-tnx-transbisn',
			  redirect: 'https://marketing.taikang.com/marketing-web/#/?tk-jny-token=%token%',
			  name: '销管中心',
			},
			cpzx: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'https://smghoms.taikang.com/#/?tk-jny-token=%token%',
			  name: '乡村振兴系统',
			},
			jypt: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'https://smghinner.taikang.com/index?jnyToken=%token%',
			  name: '经营平台'
			},
			bifx: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'https://hbi.tkhealthcare.com/assist/hospital/beijing/TaiKangJianTou/JiNianYuan/main?tk-jny-token=%token%',
			  name: 'BI分析'
			},
		};
	} else {
		var config = {
			xszc: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'http://10.130.209.160/pc/panel/jumpPanel.html?tk-jny-token=%token%',
			  name: '销售支持'
			},
			smly: {
			  code: 'RK6BW46J',
			  appId: 'tkzj-smly',
			  redirect: 'https://lesomsuat.taikang.com/shtadmin/modeSelect?tk-jny-token=%token%',
			  name: '哀伤疗愈'
			},
			sqqy: {
			  code: 'SC426_01',
			  appId: 'jny-lzrs',
			  redirect: 'https://iujy300.taikang.com/jiayuan_admin/index.php?m=admin&c=public&a=login&tk-jny-token=%token%',
			  name: '契约系统'
			},
			anlh: {
			  code: 'SC410-AL',
			  appId: 'jny-market',
			  redirect: 'https://anliao300.taikang.com/tologin?tk-jny-token=%token%',
			  name: '安疗系统'
			},
			fwyw: {
			  code: 'RK6BW46J',
			  appId: 'tkzj-tlning-sfi',
			  redirect: 'https://fi-test.taikang.com/auth/tk?tk-jny-token=%token%',
			  name: '福位系统'
			},
			yxzx: {
			  code: 'C7U4UXL8',
			  appId: 'tkzj-tnx-transbisn',
			  redirect: 'http://10.130.213.22:8080/marketing-web-test/#/?tk-jny-token=%token%',
			  name: '销管中心',
			},
			cpzx: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'https://smghomsuat.taikang.com/platform/#/?tk-jny-token=%token%',
			  name: '乡村振兴系统',
			},
			jypt: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'https://smghuat-test.taikang.com/platform/#/index?jnyToken=%token%',
			  name: '经营平台'
			},
			bifx: {
			  code: 'RK6BW46J',
			  appId: 'jny-market',
			  redirect: 'https://hbi.tkhealthcare.com/assist/hospital/beijing/TaiKangJianTou/JiNianYuan/main?tk-jny-token=%token%',
			  name: 'BI分析'
			},
		};
	}
	return config;
}