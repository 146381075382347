<template>
  <div class="home">
    <header class="home-header">
      <div class="top-box">
        <div class="left">
          <img src="~@/assets/images/logo2.png" alt="" class="logo" />
        </div>
        <div class="right">
          <img class="avatar" src="~@/assets/images/avatar.png" />
          <div class="username">{{ username }}</div>
          <div class="logout" @click="logout">退出登录</div>
        </div>
      </div>
    </header>
    <div class="home-body">
      <div class="home-body-box">
        <div class="left-box">
          <div class="service">
            <Title title="服务" />
            <div class="section">
              <template v-for="(itm, idx) in fw">
                <CardX :cardInfo="itm" :key="idx" :ckey="idx" @CardClick="CardClick" />
              </template>
            </div>
          </div>
          <div class="customer">
            <Title title="客户" />
            <div class="section">
              <template v-for="(itm, idx) in kh">
                <CardX :cardInfo="itm" :key="idx" :ckey="idx" @CardClick="CardClick" />
              </template>
            </div>
          </div>
        </div>
        <div class="right-box">
          <Title title="管理" />
          <div class="section">
            <template v-for="(itm, idx) in gl">
              <CardY :cardInfo="itm" :key="idx" :ckey="idx" @CardClick="CardClick" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="floating-popup" @click="helpChatBot">
      系统使用助手
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Title from "@/components/Title.vue";
import CardX from "@/components/CardX.vue";
import CardY from "@/components/CardY.vue";

const NodeUrl = require("url");

export default {
  name: "HomeView",
  data() {
    var data = {
      username: "",
      loginname: "",
      fw: {
        fwyw: {}, //福位业务
        anlh: {}, //安宁疗护
        smly: {}, //哀伤疗愈
        sqqy: {}, //生前契约
        cpzx: {}, //产品中心
      },
      kh: {
        xszc: {}, //销售支持
        jypt: {}, //客户经营平台
      },
      gl: {
        yxzx: {}, //营销中心
        bifx: {}, //BI分析
      },
    };
    data.site = Object.assign({}, data.fw, data.kh, data.gl);
    return data;
  },
  created() {
    this.username = this.$store.state.SubSystem.portal.username;
    this.loginname = this.$store.state.SubSystem.portal.loginname;

    var index = 0;
    var _this = this;
    for (var key in this.site) {
      if (this.$store.state.SubSystem[key]?.token) {
        continue;
      }
      setTimeout(
        function (loginname, key) {
          _this.login(loginname, key);
        },
        2000 * index,
        this.loginname,
        key
      );
      index++;
    }
  },
  components: {
    Title,
    CardX,
    CardY,
  },
  methods: {
    login(username, key, login) {
      var login = login || false;
      this.$getInterfaceData(`/index/login`, "POST", {
        username: this.loginname,
        password: "",
        client: key,
        captcha: "",
      })
        .then((res) => {
          this.$store.commit("login", {
            subcode: key,
            username: res.data.data[key].name,
            loginname: res.data.data[key].userName,
            token: res.data.data[key].token,
            usercd: res.data.data[key].userCd,
            permission: true,
          });
          //this.site[key].permission = true;
          /*
					var data    = {"client": key};
					var headers = {"tk-jny-token":this.$store.state.SubSystem[key].token, "tk-jny-usercd":this.$store.state.SubSystem[key].usercd};
					this.$getInterfaceData(`/index/portal_check`, 'POST', data,headers).then(res => {
						if(Object.keys(res.data.data).length>0) {
							this.site[key].permission = true;
						}
					});
					*/
          if (login) {
            this.goto_subsys(key);
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    },
    logout() {
      this.$message({
        message: "退出成功",
        type: "success",
      });
      this.$store.commit("logout", "portal");
      // location.href = '/login';
      this.$router.replace({ name: "login" });
    },
    goto_subsys(ckey) {
      let token = this.$store.state.SubSystem[ckey].token;
      let url = this.$subsys[ckey].redirect.replace("%token%", token);
      if (this.$store.state.SubSystem[ckey].permission) {
		this.$tools.tyym.pushEvent('门户-'+this.$subsys[ckey].name, {
		  "tkzj_modId": 'portal-'+ckey,
		});
		//pvuv
		this.$tools.tyym.pushEvent("tkzj_view_mod",{
		  "tkzj_modId": 'portal-'+ckey,// 模块ID
		});
        window.open(url);
      } else {
        this.$message({
          message: ckey + "没有权限",
          type: "warning",
        });
      }
    },
    CardClick(ckey) {
      let token = this.$store.state.SubSystem[ckey] && this.$store.state.SubSystem[ckey].token;
      if (token) {
		  this.$getInterfaceData(`/index/user_info`, "POST", {
		    token: token,
		    client: ckey
		  }).then((res) => {
			if(res.data && res.data.data && res.data.data.token) {
			  this.goto_subsys(ckey);
			} else {
			  this.login(this.$store.state.SubSystem.portal.username, ckey, true);
			}
		    console.log("res :>> ", res);
		  }).catch((err) => {
		    console.log("err :>> ", err);
		  });
      } else {
        this.login(this.$store.state.SubSystem.portal.username, ckey, true);
      }
    },
    helpChatBot() {
      document.getElementById("tkcopilot-chatbot-bubble-button").click();
    }
  },
  beforeRouteLeave(to, from, next) {
			var tkcopilot = document.getElementById("tkcopilot-chatbot-bubble-button");
			if (tkcopilot) {
				tkcopilot.remove();
			}
			next();
  },
  mounted() {
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://tkcopilot.taikang.com/embed.min.js';
			script.onload = function() {
        setTimeout(function(){
          var tkcopilot = document.getElementById("tkcopilot-chatbot-bubble-button");
          !tkcopilot && window.embedChatbot && embedChatbot();
        }, 200);
			}
      window.tkcopilotChatbotConfig = { 
        token: 'VHemfQ5Ermf4Xhue',
        fromOrigin: 'https://tkcopilot.taikang.com'
      }
			document.body.appendChild(script);
		}
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  background: #f8fbff;

  // overflow: auto;
  .home-header {
    width: 100%;
    // height: 250px;
    position: relative;
    @media screen and (max-width: 1919px) {
      // height: 227px;
      height: 247px;
      background-image: url("~@/assets/bg.png");
      background-size: 100% 800px;
      background-repeat: no-repeat;
      background-position: top center;
    }
    @media screen and (min-width: 1920px) {
      // height: 319px;
      height: 339px;
      background-image: url("~@/assets/bg.png");
      // background-size: 100% 954px;
      background-size: 100% 1075px;
      background-repeat: no-repeat;
      background-position: top center;
    }

    .top-box {
      @media screen and (max-width: 1919px) {
        height: 58px;
        padding: 0 40px 0 30px;
      }
      @media screen and (min-width: 1920px) {
        height: 70px;
        padding: 0 56px 0 40px;
      }
      width: 100%;
      // border: 1px solid red;
      
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      // -webkit-backdrop-filter: blur(5px);
      // backdrop-filter: blur(8px);
      background: rgba(52, 80, 55, 0.22);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        @media screen and (max-width: 1919px) {
          // width: 150px;
          // height: 36.6px;
          width: 122.95px;
          height: 30px;
        }
        @media screen and (min-width: 1920px) {
          // width: 160px;
          // height: 39px;
          width: 163.93px;
          height: 40px;
        }

        .logo {
          width: 100%;
          height: 100%;
        }

        // background-size: 200px 50px;
      }

      .right {
        display: flex;
        align-items: center;
        cursor: pointer;

        .avatar {
          border-radius: 50%;
          overflow: hidden;
          @media screen and (max-width: 1919px) {
            width: 24px;
            height: 24px;
          }
          @media screen and (min-width: 1920px) {
            width: 33.7px;
            height: 33.7px;
          }
        }

        .username {
          font-family: PingFangSC-Regular;
          color: #ffffff;
          font-weight: 400;
          margin-left: 8px;
          @media screen and (max-width: 1919px) {
            // width: 42px;
            height: 22px;
            line-height: 22px;
            font-size: 14px;
          }
          @media screen and (min-width: 1920px) {
            // width: 60px;
            height: 31px;
            line-height: 31px;
            font-size: 18px;
          }
        }

        .logout {
          // width: 56px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #ffffff;
          line-height: 22px;
          font-weight: 400;
          margin-left: 30px;
          padding: 5px 10px;
          @media screen and (max-width: 1919px) {
            font-size: 14px;
          }
          @media screen and (min-width: 1920px) {
            font-size: 18px;
          }

          &:active {
            background-color: gray;
            font-weight: 500;
            color: #f8fbffe0;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .home-body {
    width: 100%;
    height: calc(100% - 227px);
    flex: 1;
    // min-height: calc(100vh - 250px);
    background: #f8fbff;
    // border: 1px solid red;
    overflow: hidden;
    padding: 30px 58px;
    @media screen and (max-width: 1919px) {
      border-radius: 16px 16px 0px 0px;
      transform: translateY(-15px);
    }
    @media screen and (min-width: 1920px) {
      border-radius: 22.49px 22.49px 0px 0px;
      transform: translateY(-20px);
    }

    .home-body-box {
      display: flex;
      @media screen and (max-width: 1919px) {
        // background: pink;
        width: 1250px;
        min-width: 1250px;
        margin: 0 auto;
        .left-box {
          // flex: 1;
          width: 1019px;
          height: 100%;
          // border: 1px solid blue;
          margin-right: 40px;

          .service,
          .customer {
            // width: 100%;
            width: 1019px;

            .section {
              width: 100%;
              min-height: 113px;
              margin: 16px 40px 0 0;
              // border: 1px solid green;
              display: flex;
              // justify-content: space-between;
              flex-wrap: wrap;

              .CardX-container {
                margin-right: 16px;
                margin-bottom: 18px;
              }

              .CardX-container:nth-child(3n) {
                margin-right: 0;
                // margin-bottom: 18px;
              }
            }
          }

          .customer {
            margin-top: calc(31px - 18px);
            // margin-top: calc(31px);
          }
        }

        .right-box {
          width: 191px;
          height: 100%;

          // border: 1px solid red;
          .section {
            margin-top: 16px;

            .CardY-container {
              margin-bottom: 20px;
            }
          }
        }
      }

      @media screen and (min-width: 1920px) {
        // background: red;
        width: 1600px;
        min-width: 1600px;
        margin: 0 auto;
        .left-box {
          width: 1275px;
          height: 100%;
          // border: 1px solid blue;
          margin-right: 71px;

          .service,
          .customer {
            // width: 100%;
            width: 1275px;

            .section {
              width: 100%;
              min-height: 113px;
              margin: 16px 40px 0 0;
              // border: 1px solid green;
              display: flex;
              // justify-content: space-between;
              flex-wrap: wrap;

              .CardX-container {
                margin-right: 30px;
                margin-bottom: 20px;
              }

              .CardX-container:nth-child(3n) {
                margin-right: 0;
                // margin-bottom: 18px;
              }
            }
          }

          .customer {
            margin-top: calc(31px - 18px);
            // margin-top: calc(31px);
          }
        }

        .right-box {
          @media screen and (max-width: 1919px) {
            // width: 200px;
            width: 191px;
            height: 100%;
          }
          @media screen and (min-width: 1920px) {
            width: 254px;
            height: 100%;
          }

          // border: 1px solid red;
          .section {
            margin-top: 30px;

            .CardY-container {
              margin-bottom: 27px;
            }
          }
        }
      }
    }
  }
}

.floating-popup {
  position: fixed;
  right: -120px;
  bottom: 10px;
  width: 300px;
  padding: 20px;
  color:rgb(21, 94, 239);
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}
</style>
