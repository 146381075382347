<template>
  <div class="title-container">
    <div class="title">{{ title }}</div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "TitleCmp",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.title-container {
  width: 60px;
  height: 32px;
  // border: 1px solid red;
  .title {
    width: 40px;
    height: 24px;
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #162418;
    font-weight: 600;
  }
  .line {
    width: 100%;
    height: 4px;
    display: flex;
    &::before {
      content: '';
      display: inline-block;
      width: 35px;
      height: 4px;
      background: #325137;
      border-radius: 2px;
    }
    &::after {
      content: '';
      width: 20px;
      height: 4px;
      opacity: .4;
      display: inline-block;
      background: #335137;
    }
  }
}
</style>
