<template>
  <div class="CardY-container" @click="CardClick">
    <div class="top-box">
      <img class="gallery" :style="{ filter: isDeveloping ? 'blur(3px)' : '0px' }" :src="CardData[ckey].imgurl" alt="" />
      <img class="lock" v-if="isDeveloping" src="~@/assets/images/lock.png" alt="" />
    </div>
    <div class="middle-line"></div>
    <div class="bottom-box">
      <div class="title">
        <div class="text">{{ CardData[ckey].title }}</div>
        <div class="no-permission" v-if="isDeveloping">无权限</div>
      </div>
      <div class="content" :title="CardData[ckey].content">
        {{ CardData[ckey].content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardY",
  props: ["cardInfo", "ckey"],
  data() {
    return {
      isDeveloping: false, // 是否处于开发中
      CardData: {
        yxzx: {
          title: "销管中心",
          content: "营销中心包含各业务类型的渠道佣金、销售提成数据计算、查看、审核；团队管理等相关模块。",
          imgurl: require("@/assets/images/销管中心1.png"),
        },
        bifx: {
          title: "BI分析",
          content: "泰康生命关怀下各业务板块及经营公司的业绩、渠道、队伍、客户、产品、财务管理分析报表。",
          imgurl: require("@/assets/images/BI分析1.png"),
        },
      },
    };
  },
  methods: {
    CardClick() {
      this.$emit("CardClick", this.ckey);
    },
  },
};
</script>

<style lang="less" scoped>
.CardY-container {
  @media screen and (max-width: 1919px) {
    width: 191px;
    height: 207px;
  }
  @media screen and (min-width: 1920px) {
    width: 254px;
    height: 275px;
  }
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 6px 1px rgba(52, 80, 55, 0.08);
  border-radius: 3.96px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 6px 3px rgba(52, 80, 55, 0.1);
  }

  .top-box {
    position: relative;
    background: white;
    margin-right: 16px;
    @media screen and (max-width: 1919px) {
      width: 100%;
      height: 107px;
    }
    @media screen and (min-width: 1920px) {
      width: 100%;
      height: 143px;
    }

    .gallery {
      width: 100%;
      height: 100%;
    }

    .lock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 1919px) {
        width: 21px;
        height: 28px;
      }
      @media screen and (min-width: 1920px) {
        width: 28px;
        height: 34px;
      }
    }
  }

  .middle-line {
    width: 100%;
    height: 2px;
    background: #325136;
  }

  .bottom-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100px;

    @media screen and (max-width: 1919px) {
      padding: 20px 10px 18px;
    }
    @media screen and (min-width: 1920px) {
      padding: 27px 12px 26px;
    }

    // background: lightcoral;
    .title {
      display: flex;
      align-content: center;
      width: 100%;
      @media screen and (max-width: 1919px) {
        height: 22px;
      }
      @media screen and (min-width: 1920px) {
        height: 25px;
      }

      .text {
        font-family: PingFangSC-Medium;
        color: #1d3a25;
        font-weight: 500;
        @media screen and (max-width: 1919px) {
          font-size: 16px;
        }
        @media screen and (min-width: 1920px) {
          font-size: 18px;
        }
      }

      .no-permission {
        text-align: center;
        background: #f1e7d0;
        border-radius: 1.25px;
        font-family: PingFangSC-Regular;
        color: #966908;
        font-weight: 400;
        margin-left: 6px;
        @media screen and (max-width: 1919px) {
          width: 44px;
          height: 17px;
          line-height: 17px;
          font-size: 12px;
        }
        @media screen and (min-width: 1920px) {
          width: 57px;
          height: 23px;
          line-height: 21px;
          font-size: 15px;
        }
      }
    }

    .content {
      font-family: PingFangSC-Regular;
      color: #989aa6;
      font-weight: 400;
      // 超过两行显示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 1919px) {
        width: 171px;
        line-height: 17px;
        font-size: 12px;
      }
      @media screen and (min-width: 1920px) {
        width: 230px;
        line-height: 20px;
        font-size: 14px;
        // margin-top: 14px;
      }
    }
  }
}
</style>
