import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false; //阻止启动生产消息

// 引入组件库
import health from "health-care-os";
Vue.use(health);

import "normalize.css";
import "@/assets/style/common.css";

// import { Button, Select } from 'health-care-os';
// Vue.use(Button)
// Vue.use(Select)

// 引入http方法
import getInterfaceData from "./assets/js/getInterfaceData";
Vue.use(getInterfaceData);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

// 引入子系统配置，将app参数传递到配置中
Vue.prototype.$subsys = require("@/common/config/subsys.js")(app);
Vue.prototype.$tools  = require("@/common/utils/tools.js")(app);

app.$mount("#app");
