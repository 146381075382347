module.exports = (vm) => {
	var tools = {
		
	};
	tools.tyym = {
		init: function() {
			window.__FEWM = {
				config: {
					// 探针编码，根据具体项目而定
					systemCd: process.env.VUE_APP_MONITOR_SYSTEM_CD,
					loadPageFlag: true,
					resourceFlag: true,
					httplogInfoFlag: true,
					httpRequestFlag: false,
					httpResponseFlag: false,
					javascriptFlag: true,
					behaviorInfoFlag: true,
					userInfoFlag: true,
					userEventFlag: true,
					// 数组中写入指定接口的特征值，即可忽略对其的监听。例如写入hip-mst-domain时即可对所有具有该指定值的接口忽略采集
					exclude: {
						ajaxURL: []
					}
				}
			}
			console.log(window.__FEWM)
			window.scriptWebmonitor = document.createElement('script');
			scriptWebmonitor.src = 'https://ui.healthcare.taikang.com/hf-monitor-js/webmonitor.min.2.0.js';
			document.head.insertBefore(scriptWebmonitor, document.head.firstChild);
			//探针初始化，必须调
			window.scriptWebmonitor.onload = function() {
			   window.tyym && window.tyym.initUpload();
			}
		},
		pushEvent: function(e_name, e_options) {
			if(window.tyym) {
				window.tyym.pushEvent(e_name, e_options);
			} else {
				window.scriptWebmonitor.addEventListener("load", () => {
					window.tyym && window.tyym.pushEvent(e_name, e_options);
				});
			}
		},
		setUserInfo: function(user_info) {
			if(window.tyym) {
				window.tyym.setUserInfo(user_info);
			} else {
				window.scriptWebmonitor.addEventListener("load", () => {
					window.tyym && window.tyym.setUserInfo(user_info);
				});
			}
		}
	};
	return tools;
}